$shadow-lg: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$shadow-md: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

.App {
  text-align: center;
  background-color: #33ACFF;
  width: 100%;
}
p {
  margin: 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
button {
  cursor: pointer;
}
.ds-lg {
  box-shadow: $shadow-lg;
}
.ds-md {
  box-shadow: $shadow-md;
}