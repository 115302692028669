$shadow-lg: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$shadow-md: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$pk-red: #E3350D;


.landing {
    padding: 1em 0;
    margin: 0 auto;
    height: auto;
}
.region-list {
    list-style-type: none;
    display: flex;
    justify-content: center;
}
.region-list li {
    margin: 0 .25em;
}
.region-list li button {
    width: 5em;
    border-radius: 1em;
    border: none;
    color: #fff;
    background-color: $pk-red;
    box-shadow: $shadow-md;
    font-size: 1.25em;
}
@media only screen and (max-width: 600px) {
    .region-list {
      overflow-y: scroll;
    }
  }
.dex-section {
    max-width: 1200px;
    margin: 0 auto;
}
.dex-section > .dex-list {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: stretch;
    justify-items: center;
    flex-wrap: wrap;
    justify-content: center;
}
.pokemon-basic {
    width: 140px;
    height: 215px;
    padding: .5rem;
    border-radius: .5rem;
    background: #fff;
    margin: 0 5px;
    margin: .5rem;
    box-shadow: $shadow-lg;
    position: relative;
    cursor: pointer;
}
.basic-img {
    height: 50%;
    width: auto;
    background: #F2F2F2;
    border-radius: .5rem;
}
.pokemon-basic > p:nth-child(3) {
    font-size: 1.5rem;
    margin: 0;
    color: #000;
    padding: 0;
}